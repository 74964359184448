import { Alert, AlertIcon, VStack } from "@chakra-ui/react";
import { Loader } from "../components/loading/Loader";
import useHasPortfolioAccess from "../components/portfolio-dashboard/data/useHasPortfolioAccess";
import PortfolioDashboardPage from "./dashboard";
import PropertiesPage from "./property";

const IndexPage = () => {
  const { hasPortfolioAccess, isLoading, error } = useHasPortfolioAccess();

  if (isLoading) {
    return (
      <VStack h="100vh">
        <Loader />
      </VStack>
    );
  }

  if (error) {
    return (
      <VStack>
        <Alert status="error">
          <AlertIcon />
          There was an error loading your data, please try again.
        </Alert>
      </VStack>
    );
  }

  if (hasPortfolioAccess) {
    return <PortfolioDashboardPage />;
  }

  return <PropertiesPage />;
};

export default IndexPage;
